<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons class="back" slot="start">
        <ion-back-button default-href="/home" text="" v-if="sharedState.showBackButton && !isFilteredResources"></ion-back-button>
        <ion-button @click="closeSearch" v-else-if="sharedState.isSearching">
          <ion-icon slot="icon-only" :icon="arrowBack"></ion-icon>
        </ion-button>
        <ion-button @click="clearFilters" v-if="isFilteredResources">
          <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title><img src="/assets/img/oscER-jr-logo-header.svg"></ion-title>
      <ion-buttons class="right-btns" slot="end">
        <div class="audio">
          <AudioPlayer />
        </div>
        <ion-button @click="openFiltersModal" :class="'filter-btn' + (this.numberOfFilters !== 0 ? ' active' : '') + (!this.hasFilteredResources ? ' hide' : '')">
          <div class="filter-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
              <path fill="#fff" d="M.266 2.113c2.539 3.4 7.227 9.7 7.227 9.7v7.874c0 .722.565 1.313 1.256 1.313h2.514c.691 0 1.257-.59 1.257-1.313v-7.875s4.675-6.3 7.214-9.699c.641-.866.05-2.113-.993-2.113H1.26C.216 0-.375 1.247.266 2.113Z"/>
            </svg>
            <span class="badge">{{ this.numberOfFilters }}</span>
          </div>
        </ion-button>        
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon, modalController } from '@ionic/vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import ResourceFiltersModal from '@/components/ResourceFiltersModal.vue';
import { store } from '@/store';
import { arrowBack, filterOutline, chevronBackOutline } from 'ionicons/icons';

export default  {
  name: 'AppHeader',
  components: { AudioPlayer, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon },
  setup() {
    return {
      arrowBack,
      filterOutline,
      chevronBackOutline,
    }
  },
  data() {
    return {
      sharedState: store.state,
      numberOfFilters: 0,
      currentPath: null,
      hasFilteredResources: false,
    }
  },
   computed: {
    isFilteredResources: function() { return (this.currentPath === '/resources' && this.numberOfFilters !== 0); }
  },
   watch: {
    'sharedState.resourceFilters': function() {
      this.numberOfFilters = this.sharedState.resourceFilters.length;
    },
    '$route.path': {
      async handler (path) {
        this.currentPath = path;
      }
    },
  },
  async beforeMount() {
    await this.getResourcesWithFilters();
    this.itemData = this.sharedState.filteredResources;
  },
  mounted() {
    this.$el.querySelector('ion-back-button')?.addEventListener('click', () => this.closeSearch());
  },
  methods: {
    /**
     * Clears resource filters & navigates to Resources page
     */
    clearFilters() {
      this.sharedState.resourceFilters = [];
      this.sharedState.filteredResources = [];

      // Navigate to the resources page
      if (this.currentPath !== '/resources') this.$router.push('/resources');
    },
    closeSearch() {
      store.updateItem('isSearching', false);
    },
    getResourcesWithFilters: async function() {
      const resourcesWithFilters = Object.values(await store.getResourcesWithFilters());
      this.sharedState.resourcesForFilters = resourcesWithFilters;
      this.hasFilteredResources = (resourcesWithFilters && resourcesWithFilters.length !== 0);
    },
    /**
     * Opens resource filters modal
     */
    async openFiltersModal() {
      const modal = await modalController.create({ component: ResourceFiltersModal, cssClass: 'filter-modal' });
      modal.present();
      await modal.onWillDismiss();
    }
  },
 
}
</script>

<style scoped>
  img {
    margin: 0 auto;
    max-width: 108px;
    max-height: 36px;
    display: block;
  }

  .back {
    position: absolute;
    left: 0.25em;
  }

  .right-btns {
    position: absolute;
    right: 0.25em;
  }

  .filter-icon {
    display: flex;
    position: relative;
  }

  .filter-icon .badge {
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--ion-color-secondary);
    bottom: -1px;
    color: var(--ion-color-secondary);
    display: none;
    font-size: 8px;
    height: 12px;
    justify-content: center;
    position: absolute;
    right: -4px;
    width: 12px;
  }

  .filter-btn.active .badge {
    display: block;
  }

  .filter-btn.active svg path {
    fill: var(--ion-color-secondary);
  }

  .filter-btn.hide {
    display: none;
  }
</style>